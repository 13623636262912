@mixin navbar-layout {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.navigation-brand {
    @extend .navbar;
    @extend .navbar-expand-md;
    @extend .fixed-top;
    height: $header-height;
    background-color: $white;
    padding-top: 0;
    padding: 0.5rem 1rem;
    @include media-breakpoint-up(sm) {
        @include navbar-layout;
    }
    @include media-breakpoint-down(sm) {
        @include navbar-layout;
    }
    &::after {
        content: '';
        bottom: 0;
        position: absolute;
        display: block;
        width: 100%;
        background-color: $header-border-color;
        height: $header-border-height;
        left: 0;
    }
    img{
        max-height: $header-height - $header-border-height;
    }
}