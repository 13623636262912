.form-select {
    border-color: #d4d4d4;
    background-image: url("./assets/icons/chevron.svg");
}
.custom-validation-message-container {
    padding-top: 1rem;
    color: #4f4f4f;
    font-size: 15px;
    p{
        margin-bottom: 0.5rem;
    }
    ul.validation-messages-list {
        list-style: none;
        padding-left: 8px;
        font-size: 12px;
        line-height: 1.5;

        li.valid:before {
            content: url("./assets/icons/tick-pass.svg");
            margin-right: 10px;
            vertical-align: middle;
        }

        li.invalid:before {
            content: url("./assets/icons/tick-warning.svg");
            margin-right: 8px;
            vertical-align: middle;
        }
    }
    .note{
        font-size: 11px;
    }
}
