﻿// $input-border-radius: 2px;
// $input-border-radius-lg: 2px;
// $input-border-radius-sm: 2px;

/* COLORS */

$mine-shaft: #212121;
$greyish-brown: #4f4f4f;
$black: #2e2e2e;
$teal-blue: #007bc7;
$white: #ffffff;
$cool-grey: #a8b1b6;
$default-grey:#f2f2f2;
$white-two: #efefef;
$brandbar-green:#106550;
$grey:#959595;
$alto: #d4d4d4;
$alto-light: #d8d8d8;

/* font family */

$font-proxima: "ProximaNova";
$font-size-lg: 1.375rem; //22px
$font-size-md: .938rem; //15px
$font-size-sm: .875rem; //14px
$font-size-xs: .75rem; //12px

/* Common borders */

$border-radius: 2px;
$border-radius-lg: 2px;
$border-radius-sm: 2px;
$border-color: #b2b2b2;
$table-border-color: rgba(0, 0, 0, .05);
$input-border-color: $border-color;
$dropdown-border-color: $border-color;

/* Header */

$header-height: 4rem;
$toaster-height: $header-height;
$navbar-height: $header-height;
$header-border-color: $default-grey;
$header-border-height: .38rem;

/*footer*/

$brandbar-height: 1.25rem;
$footerbar-height: 4rem;
$footer-position: fixed;
$footer-fontsize: .755rem;
$footerlink-fontsize: .755rem;

/* main panel */

$mainpanel-padding-top: $navbar-height+5.3rem;
$mainpanel-padding-bottom: 6.5rem;
$mainpanel-padding-top-phone: $navbar-height+2rem;
$mainpanel-padding-bottom-phone: $footerbar-height + $brandbar-height;

/* background */
$background-padding-top: $mainpanel-padding-top;
$background-padding-bottom: $mainpanel-padding-bottom;
$hero-image-url:"resources/images/bg.jpg";
$background-padding-top-phone: $mainpanel-padding-top-phone;
$background-padding-bottom-phone: $footerbar-height + $brandbar-height;

/* login panel controls */

$signup-title-font-size: $font-size-lg;
$signup-text-font-size: $font-size-md;
$selectlanguage-background:$default-grey;
$selectlanguage-color:$teal-blue;
$selectlanguage-padding:1rem;
$selectlanguage-font-size: $font-size-sm;
$personalinfo-background:$white;
$personalinfo-padding:1rem;
$personalinfo-panelborder-fill: -$personalinfo-padding;
$loginpanel-control-paddingleft:1rem;
$loginpanel-control-paddingright:1rem;
$loginpanel-control-width:100%;
$nav-link-font-size: $font-size-sm;
$input-label-font-size: $font-size-md;

/* Information panels */
$information-header-font-size: 1.125rem;
$information-mobile-buttons-width: 47%;

/* buttons */

$btn-accept-backgroundcolor:$teal-blue;
$btn-cancel-backgroundcolor:$white;
$btn-cancel-border-color:$alto;
$btn-custom-height:3rem;
$btn-custom-width:100%;
$btn-cutom-radius:$border-radius;
$btn-accept-color:$white;
$btn-cancel-color:$teal-blue;
$btn-accept-hover-color: #005184;
$btn-cancel-hover-color: $white; 
$btn-disabled-background-color: #d8d8d8;
$btn-disabled-color: #6b6b6b;

/* Links */

$link-color: $teal-blue;
$link-decoration: none;
$link-hover-color: darken($link-color, 10%);
$link-hover-decoration: none;

/* Text */

$text-color: $mine-shaft;
$text-color-light: $greyish-brown;

/* Controls */

$brand-warning: #c00a0a;
$checkbox-background-color: $teal-blue;
$dropdown-toggle-color: $selectlanguage-color;
$dropdown-bg: $white;
$dropdown-item-hover-bg: rgba(0, 0, 0, 0.05);
$validation-warning-border-color: $brand-warning;
$validation-text-colour: $brand-warning;
$validation-warning-bg-color: rgba(191, 10, 11, 0.05);
$validation-message-font-size: $font-size-sm;
$panel-border-color: $default-grey;