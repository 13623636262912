.dropdown {
    width: 100%;
    
    & &-toggle {
        color: $dropdown-toggle-color !important;
        font-weight: 600 !important;
        border: $dropdown-border-width solid $dropdown-border-color;
        padding-left: 0.5rem;
        
        &-label {
            display: inline-block;
            width: 100%;
        }

        &:focus {
            outline: 2px solid #3489d1;
            outline-offset: 2px;
        }
    }

}
