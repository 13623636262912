.panel-label {
    @extend .h6;
    font-size: $input-label-font-size;
    font-weight: bold;
    font-family: $font-proxima;
}

.panel-control {
    @include login-panel-controls($loginpanel-control-paddingleft, $loginpanel-control-paddingright, $loginpanel-control-width);
}

.form-field-input-error {
    @extend .is-invalid;
}

.panel {
    &-info,
    &-content,
    &-footer {
        @include panel-container($personalinfo-padding, $personalinfo-background);
        .panel-border {
            height: 1px;
            background-color: $default-grey;
            margin-bottom: .9rem;
            margin-left: $personalinfo-panelborder-fill;
            margin-right: $personalinfo-panelborder-fill;
        }
    }
}