%kf-button {
    letter-spacing: .2px;
    cursor: pointer;
}

.accept,
.next {
    @include custom-button($btn-custom-width, $btn-custom-height, $btn-cutom-radius, $btn-accept-backgroundcolor, $btn-accept-color, $btn-accept-hover-color, $btn-disabled-background-color, $btn-disabled-color);
    @extend %kf-button;

    &:focus {
        @include custom-control-focus($btn-accept-backgroundcolor, $btn-accept-color);
    }
  
}

.cancel {
    @include custom-button($btn-custom-width, $btn-custom-height, $btn-cutom-radius, $btn-cancel-backgroundcolor, $btn-cancel-color, $btn-cancel-hover-color, $btn-disabled-background-color, $btn-disabled-color);
    @extend %kf-button;

    border: 1px solid $btn-cancel-color;

    &:focus {
        @include custom-control-focus($btn-accept-backgroundcolor, $btn-cancel-color);
        outline: 0 !important;
        box-shadow: 0 0 5px 0 rgba(0, 123, 199, 0.5);
        border: solid 2px $btn-cancel-color
    }
    &:hover{
        color: $btn-accept-hover-color !important;
        border: 1px solid $btn-accept-hover-color;
    }
}
