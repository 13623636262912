.signinpanel {
    &-greeting {
        font-size: $signup-title-font-size;
        font-weight: bold;
        //Override effects of using an h2 instead of p
            margin: inherit;
        //End of overrides
        &-accent {
            font-weight: 600;
            display: block;
        }
    }
    &-statement {
        font-size: $signup-text-font-size;
        color: $text-color-light;
        //Override effects of using an h2 instead of p
        font-weight: inherit;
        margin: 1rem 0;
        line-height: inherit;
        //End of overrides
        &-link {
            font-weight: 600;
        }
    }
    &-no-display {
        display: none;
    }
    .panel-border {
        margin-bottom: 0;
    }
    label {
        letter-spacing: .1px;
    }
    .custom-control {
        margin-bottom: 0;
        padding-left: 0;
        margin-right: 0;

        &-input {
            position: relative;
            top: 4px;
            width: 19.2px;
            height: 19.2px;
            line-height: 1.1; 
            padding: 0;
            display: inline-block;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: rgb(221,221,221);
            background-size: 10px 10px;
            background-position: center;
            background-repeat: no-repeat;
            opacity: 1;
            z-index: inherit;
            pointer-events: auto;
            border-radius: .2rem;
            border: 1px solid black;
            
            &:focus {
                @include control-focus;
                border-color: black;
            }
            &:checked {
                background-color: $checkbox-background-color;
                background-image: url("./assets/icons/white-checkmark.svg");
            }
            &:indeterminate {
                background-image: url("./assets/icons/white-minus.svg");
            }
        }
        &-text {
            margin-left: .4rem;
        } //with default margin and display the mobile layout is incorrect
    }
 &-explanation {
     @extend .form-field-input;
     font-weight: bold;
 }
}

.signinpanel-language {
    @include panel-container($selectlanguage-padding, $selectlanguage-background);

    display: flex;

    .dropdown {
        font-size: $selectlanguage-font-size;
    }
}

.language-go-button {
    width: auto;
    height: 100%;
    margin-left: 10px;
}

.form-field-input {
    @extend .panel-control;
    margin-bottom: 1rem;

    label {
        @extend .panel-label;
    }
    input {
        @extend .form-control;
        font-size: $font-size-md;
        font-family: $font-proxima;
        

        &:focus {
            outline: 0;
            border: 2px solid #007bc7;
            outline-offset: 2px;
            box-shadow: 0 0 5px 0 rgba(0, 123, 199, 0.5);
        }
    }
    &.invalid {
        input {
            border: 1px solid $validation-warning-border-color;
            box-shadow: none;
        }
        span {
            font-size: $validation-message-font-size;
            font-weight: bold;
            color: $validation-text-colour;
            padding-left: .3rem;
        }
    }
}

.form-error {
    background-color: $validation-warning-bg-color;
    border: 1px solid $validation-warning-border-color;
    font-size: $validation-message-font-size;
    font-weight: bold;
    color: $black;
    padding: 0.5em 1.5em;
    list-style-type: disc;
    margin-left: 1rem;
    margin-right: 1rem;             
}

.form-button {
    @extend .panel-control;
    margin: 2rem 0;
    a,
    button:not(:first-child) {
        margin: 1em 0;
    }
}

.signin-nav-link {
    @extend .panel-control;
    @extend .panel-label;
    font-size: $nav-link-font-size;
    font-weight: 600;
}

.policy-form {
    padding: $personalinfo-padding;
}

#languageInstruction {
    display: none; 
}

#liveRegion {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
