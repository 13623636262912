html,
body,
main {
    height: 100%;
    font-size: 16px;
}

@include media-breakpoint-down(sm) {
    main {
        width: 100%;
    }
}

.App {
    position: relative;
}

.background-large {
    padding-top: $background-padding-top;
    padding-bottom: $background-padding-bottom;
    @include media-breakpoint-down(sm) {
        padding-top: $background-padding-top-phone;
        padding-bottom: $background-padding-bottom-phone;
    }
}

.hero-image {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url($hero-image-url);
}

.main-center {
    @extend .container-fluid; // default mobile first
    margin: 0 auto;
    padding: 0;
    padding-bottom: .4rem;
    width: 100%;
    margin-left: 0;
    background-color: $white;
    position: relative;
   
    // tv and large desktop
    @include media-breakpoint-up(lg) {
        width: 400px;
        margin-left: 12%;
    }

    //tablet and medium desktop
    @include media-breakpoint-only(md) {
        margin-left: 25.5%;
        width: 49%;
    }

    // phone
    @include media-breakpoint-down(sm) {
        min-height: 100%;
        height: auto;
        padding-bottom: $mainpanel-padding-bottom-phone;
    }
}