﻿@mixin custom-button($width, $height, $border-radius, $backgroud-color, $color, $hover-color, $disabled-bg-color, $disabled-color) {
    --#{$prefix}btn-padding-x: #{$btn-padding-x};
    --#{$prefix}btn-padding-y: #{$btn-padding-y};

    width: $width;
    height: $height;
    border-radius: $border-radius;
    background-color: $backgroud-color;
    border: none;
    color: $color;
    padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    
    &:disabled {
        background-color: $disabled-bg-color !important;
        color: $disabled-color;
        cursor: not-allowed;
    }
    &:not(:disabled):active,
    &:not(:disabled):hover {
        background-color: $hover-color;
        color: $color;
    }
}

@mixin panel-container($padding, $background-color) {
    padding: $padding;
    background-color: $background-color;
}

@mixin login-panel-controls($padding-left, $padding-right, $width) {
    width: $width;
    padding-left: $padding-left;
    padding-right: $padding-right;
}

@mixin flex-row($flex-wrap: nowrap, $justify-content: center, $align-items: center) {
    display: flex;
    flex-direction: row;
    flex-wrap: $flex-wrap;
    justify-content: $justify-content;
    align-items: $align-items;
}

@mixin unstyled-link($color) {
    &,
    &:active,
    &:hover,
    &:visited,
    &:focus {
        color: $color;
        text-decoration: none;
    }
}

@mixin control-focus {
    outline: 2px solid #3489d1;
    outline-offset: 2px;
}

@mixin custom-control-focus($border-color, $font-color) {
    outline: 2px solid $border-color !important;
    outline-offset: 2px !important;
    box-shadow: 0 0 5px 0 rgba(0, 123, 199, 0.5);
    color: $font-color;
}
