@import "./styles/bootstrap";
@import "./styles/common/common";
@import "./components/components";
@import "./styles/rtl/rtl";
@import "./styles/common/resize";

body {
    font-family: $font-proxima;
    color: $text-color;
}

.bodyArial {
    font-family: Arial !important;
    color: $text-color;
    * {
        font-family: Arial !important;
    }
}

/* Globally shared styles  TODO : delete once styles are in place */
.error {
    @extend .text-danger;
}

.warning {
    @extend .text-warning;
}

.disabled-link {
    pointer-events: none;
}

a {
    text-decoration: none;
    &:hover {
        opacity: 0.95;
        text-decoration: underline;
    }
}
.form-control, .form-field-input input, .signinpanel-explanation input {
    border: 1px solid $input-border-color;
}