.error-page {
    header {
        background-color: white;
        height: 5rem;
        .navigation-brand-image {
            display: flex;
            height: 100%;
        }
    }
    
    &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10rem;
        gap: 1rem;
    
        .title {
            font-weight: 600;
        }
    
        .description {
            font-size: 18px; 
        }
    
        .btn-action {
            background-color: $brandbar-green;
            color: white;
            font-weight: 600;
            border: none;
            padding: 0.5rem 1.2rem;
            border-radius: 10px;
        }
    
        .icon-wrapper {
            width: 230px;
            height: 120px;
            .error-icon {
                width: 100%;
                height: 100%;
                background-image: url("./assets/icons/error.png");
                display: block;
                background-size: cover;
            }
        }
    }
}

.footer .footer-bar {
    align-items: center;
    background-color: $black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 5rem;

    .copyright {
        color: #fff;
        font-size: $footer-fontsize;
        margin-left: 1rem;
    }

    .links-right {
        color: #959595;
        font-family: ProximaNova;
        font-size: $footerlink-fontsize;
        padding-right: 1rem;

        ul {
            margin: 0;
            padding: 0;
        }
    }
}