.mandatory-note {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  font-size: 12px;
  color: #333;
}

.mandatory-asterisk {
  font-weight: bold;
  margin-right: 5px;
  font-size: 18px;
  margin-top: 7px;
}
