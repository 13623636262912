@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    -ms-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.spinner-inline {
    height: 2rem;
}

.spinner-fullscreen-container img {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    text-align: center;
    width: 70px;
    height: 70px;
}

.spinner-fullscreen-container {
    min-height: 100%;
    height: 400px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url("resources/images/spinner-logo.svg") center no-repeat;
    background-color: #FFFFFF;
    background-size: 80px;    
}